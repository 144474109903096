import React from "react";
import "./ProjectPage.css";
import { Link } from "react-router-dom";

import projectphoto1 from "../images/projects/Project7/1.jpg";
import projectphoto2 from "../images/projects/Project7/2.jpg";
import projectphoto3 from "../images/projects/Project7/3.jpg";
import projectphoto4 from "../images/projects/Project7/5.jpg";
import projectphoto5 from "../images/projects/Project7/2.jpg";

const ProjectHero = () => {
  return (
    <section className="project-hero">
      <div className="hero-content">
        <h1>AQUA Retail Store Interiors</h1>
        <span className="location">Dubai, UAE</span>
        <p>
          AQUA is a clothing fashion brand in Dubai Mall. The design intention
          combines sandblasted marble with oak veneer.
        </p>
      </div>
      <div className="hero-image-container">
        <img
          src={projectphoto1}
          alt="AQUA Retail Store Interior"
          className="hero-image"
        />
      </div>
    </section>
  );
};

const FacilityMasterplan = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The master plan for AQUA's retail store involved a strategic approach
          to maximizing display space while maintaining a clean and modern
          aesthetic. The design incorporates flexible shelving and display
          units, allowing for easy reconfiguration as the product offerings
          evolve.
        </p>
      </div>
    </section>
  );
};

const ProjectSection = () => {
  return (
    <section className="section-sc-1vjgz5n-0 eeeNzp">
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu">
        <div className="ratio-img-wrapper-sc-8qesjk-1 project-image-sc-di9xrj-0">
          <img
            alt="Luxurious Display Areas"
            className="ratio-img-sc-8qesjk-0 dwEpAz"
            src={projectphoto2}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-1">
          The luxurious display areas are designed to highlight the high-quality
          products AQUA offers. The warm lighting and modern fixtures create an
          inviting atmosphere that draws customers into each section,
          encouraging them to explore the curated collections.
        </p>
      </div>
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu reverse">
        <div className="ratio-img-wrapper-sc-8qesjk-2 project-image-sc-di9xrj-0">
          <img
            alt="Spacious and Organized Layout"
            className="ratio-img-sc-8qesjk-0 dwEpAz2"
            src={projectphoto3}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-2">
          The spacious and organized layout ensures a seamless shopping
          experience. Wide aisles, strategic product placements, and comfortable
          pathways make it easy for customers to navigate the store while
          enjoying a premium shopping environment.
        </p>
      </div>
    </section>
  );
};

const FacilityMasterplan2 = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The interior design also includes areas for exclusive collections and
          seasonal displays, allowing AQUA to showcase its products in a dynamic
          and engaging way. The design reflects the brand's identity while
          providing flexibility for future updates and promotions.
        </p>
      </div>
    </section>
  );
};

const UniqueSection = () => {
  return (
    <section className="uniqueBeNxCw">
      <div className="uniqueEBZEyY">
        <div className="uniqueECjdAA">
          <h2 className="uniqueFLhVRG">Explore More About Us</h2>
        </div>
        <div className="uniqueWOhqI">
          <div className="uniqueDZUreZ">
            <Link to="/services" aria-label="Services">
              {" "}
              {/* Changed to Link */}
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto4}
                    alt="Our Services"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Discover Our Services</h3>
                <span className="uniqueJxeAbl">View Services</span>
              </div>
            </Link>{" "}
            {/* Link tag closed */}
          </div>
          <div className="uniqueDZUreZ">
            <Link to="/about" aria-label="About Us">
              {" "}
              {/* Changed to Link */}
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto5}
                    alt="About Us"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Learn About Our Journey</h3>
                <span className="uniqueJxeAbl">Who We Are</span>
              </div>
            </Link>{" "}
            {/* Link tag closed */}
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPage7 = () => {
  return (
    <>
      <ProjectHero />
      <FacilityMasterplan />
      <ProjectSection />
      <FacilityMasterplan2 />
      <UniqueSection />
    </>
  );
};

export default ProjectPage7;
