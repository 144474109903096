import React from "react";
import { Link } from "react-router-dom"; // Import Link component
import "./SpotlightSection.css";

import pr1 from "../images/projects/Project1/1.jpg";
import pr2 from "../images/projects/Project2/1.jpg";
import pr3 from "../images/projects/Project3/1.jpg";
import pr4 from "../images/projects/Project4/1.jpg";
import pr5 from "../images/projects/Project5/1.jpg";
import pr6 from "../images/projects/Project6/1.jpg";
import pr7 from "../images/projects/Project7/1.jpg";
import pr8 from "../images/projects/Project8/1.jpg";

const SpotlightSection = () => {
  const spotlightItems = [
    {
      title: "Urbanist Retail Fitout",
      imageUrl: pr1,
      link: "/ProjectPage1",
    },
    {
      title: "UKCBC Campus Interiors",
      imageUrl: pr2,
      link: "/ProjectPage2",
    },
    {
      title: "SDY Corporate Office Interiors",
      imageUrl: pr3,
      link: "/ProjectPage3",
    },
    {
      title: "NBF Bank Branch Interiors",
      imageUrl: pr4,
      link: "/ProjectPage4",
    },
    {
      title: "MAG Office Interiors",
      imageUrl: pr5,
      link: "/ProjectPage5",
    },
    {
      title: "FEWA Office Interiors",
      imageUrl: pr6,
      link: "/ProjectPage6",
    },
    {
      title: "AQUA Retail Store Interiors",
      imageUrl: pr7,
      link: "/ProjectPage7",
    },
    {
      title: "Lead Office Interiors",
      imageUrl: pr8,
      link: "/ProjectPage8",
    },
  ];

  return (
    <section className="spotlight-section">
      <div className="container">
        <div className="news-content">
          <div className="news-heading">
            <h1 className="news-title">Our Projects</h1>
          </div>
        </div>
        <div className="img-grid">
          {spotlightItems.map((item, index) => (
            <div className="grid-item" key={index}>
              <Link to={item.link} style={{ display: "block" }}>
                <div className="angled-img">
                  <div className="ratio-img-wrapper">
                    <img
                      alt={item.title}
                      className="ratio-img"
                      src={item.imageUrl}
                    />
                  </div>
                </div>
                <h2 className="item-heading">{item.title}</h2>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SpotlightSection;
