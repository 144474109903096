import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import heroImage from "../images/mainslider/5.jpg";
import join from "../images/projects/Project6/1 - Copy.jpg";
import "./ServicesPage.css";

// Import images for projects
import project1Image2 from "../images/projects/Project1/2.jpg";
import project1Image5 from "../images/projects/Project1/5.jpg";
import project2Image2 from "../images/projects/Project2/2.jpg";
import project2Image4 from "../images/projects/Project2/4.jpg";
import project2Image7 from "../images/projects/Project2/7.jpg";
import project3Image3 from "../images/projects/Project3/3.jpg";
import project3Image4 from "../images/projects/Project3/4.jpg";
import project3Image5 from "../images/projects/Project3/5.jpg";
import project3Image7 from "../images/projects/Project3/7.jpg";
import project3Image6 from "../images/projects/Project3/6.jpg";
import project3Image9 from "../images/projects/Project3/9.jpg";
import project4Image2 from "../images/projects/Project4/2.jpg";
import project4Image4 from "../images/projects/Project4/4.jpg";
import project5Image3 from "../images/projects/Project5/3.jpg";
import project5Image2 from "../images/projects/Project5/2.jpg";
import project5Image6 from "../images/projects/Project5/6.jpg";
import project5Image7 from "../images/projects/Project5/7.jpg";
import project5Image9 from "../images/projects/Project5/9.jpg";
import project6Image4 from "../images/projects/Project6/4.jpg";
import project6Image5 from "../images/projects/Project6/5.jpg";
import project6Image6 from "../images/projects/Project6/6.jpg";
import project6Image7 from "../images/projects/Project6/7.jpg";
import project7Image2 from "../images/projects/Project7/2.jpg";
import project7Image5 from "../images/projects/Project7/5.jpg";
import project8Image9 from "../images/projects/Project8/9.jpg";
import project8Image6 from "../images/projects/Project8/6.jpg";
import project8Image5 from "../images/projects/Project8/5.jpg";
import project8Image4 from "../images/projects/Project8/4.jpg";
import project8Image3 from "../images/projects/Project8/3.jpg";
import project8Image2 from "../images/projects/Project8/2.jpg";

const projects = [
  { image: project1Image2, alt: "Project 1 - Image 2" },
  { image: project1Image5, alt: "Project 1 - Image 5" },
  { image: project2Image2, alt: "Project 2 - Image 2" },
  { image: project2Image4, alt: "Project 2 - Image 4" },
  { image: project2Image7, alt: "Project 2 - Image 7" },
  { image: project3Image3, alt: "Project 3 - Image 3" },
  { image: project3Image4, alt: "Project 3 - Image 4" },
  { image: project3Image5, alt: "Project 3 - Image 5" },
  { image: project3Image7, alt: "Project 3 - Image 7" },
  { image: project3Image6, alt: "Project 3 - Image 6" },
  { image: project3Image9, alt: "Project 3 - Image 9" },
  { image: project4Image2, alt: "Project 4 - Image 2" },
  { image: project4Image4, alt: "Project 4 - Image 4" },
  { image: project5Image3, alt: "Project 5 - Image 3" },
  { image: project5Image2, alt: "Project 5 - Image 2" },
  { image: project5Image6, alt: "Project 5 - Image 6" },
  { image: project5Image7, alt: "Project 5 - Image 7" },
  { image: project5Image9, alt: "Project 5 - Image 9" },
  { image: project6Image4, alt: "Project 6 - Image 4" },
  { image: project6Image5, alt: "Project 6 - Image 5" },
  { image: project6Image6, alt: "Project 6 - Image 6" },
  { image: project6Image7, alt: "Project 6 - Image 7" },
  { image: project7Image2, alt: "Project 7 - Image 2" },
  { image: project7Image5, alt: "Project 7 - Image 5" },
  { image: project8Image9, alt: "Project 8 - Image 9" },
  { image: project8Image6, alt: "Project 8 - Image 6" },
  { image: project8Image5, alt: "Project 8 - Image 5" },
  { image: project8Image4, alt: "Project 8 - Image 4" },
  { image: project8Image3, alt: "Project 8 - Image 3" },
  { image: project8Image2, alt: "Project 8 - Image 2" },
];

// Hero section with just the image
const ServicesHero = () => (
  <section className="ServicesHero">
    <div className="ServicesHero__MediaWrap">
      <div className="ServicesHero__HeroImg">
        <img src={heroImage} alt="Services Hero" />
      </div>
    </div>
  </section>
);

// Services Description Section
const ServicesDescription = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="ServicesDescription">
      <div className="ServicesDescription__Wrapper">
        <div className="ServicesDescription__Left">
          <div className="ServicesDescription__VerticalText">KOTTOOR</div>
        </div>

        <div className="ServicesDescription__Right">
          <div className="ServicesDescription__PopupContent">
            <h1 className="ServicesDescription__Title">Services</h1>
            <p>
              At Kottoor, our services include turnkey interior fit-outs and all
              related services, which encompass gypsum and glass partitions,
              cladding and flooring works, MEP and joinery works, as well as and
              furniture solutions. We specialize in providing Turnkey Design and
              Build services to our clients
            </p>
           
           
          </div>
          {/* Card Palette for Services */}
          <div className="ServicesDescription__Cards">
            <div
              className="service-card"
              onClick={() => scrollToSection("architecture-section")}
            >
              Joinery
            </div>
            <div
              className="service-card"
              onClick={() => scrollToSection("engineering-section")}
            >
              Space Planning
            </div>
            <div
              className="service-card"
              onClick={() => scrollToSection("architecture-section-2")}
            >
              Turnkey Fitouts
            </div>
            <div
              className="service-card"
              onClick={() => scrollToSection("engineering-section-2")}
            >
              Furniture
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Other sections remain unchanged
const ArchitectureSection = () => (
  <section id="architecture-section" className="architecture-section">
    <div className="architecture-images">
      <img src={project1Image2} alt="Architecture Image 1" className="image1" />
      <img src={project1Image5} alt="Architecture Image 2" className="image2" />
    </div>
    <div className="architecture-text">
      <h2>Joinery</h2>
      <p>
        Our woodwork is of high quality and meticulously detailed. Every detail
        is carefully defined and finished as designed. We produce joinery to
        match every detail as drafted.
      </p>
    </div>
  </section>
);

const EngineeringSection = () => (
  <section id="engineering-section" className="engineering-section">
    <div className="engineering-text">
      <h2>Space Planning</h2>
      <p>
        Every space is unique in its own way. Appropriate space planning
        considering its strengths and limitations, helps maximize utilization.
        This also ensures the space is used optimally for its intended function.
      </p>
    </div>
    <div className="engineering-images">
      <img src={project3Image3} alt="Engineering Image 1" className="image1" />
      <img src={project3Image4} alt="Engineering Image 2" className="image2" />
    </div>
  </section>
);

const ArchitectureSection2 = () => (
  <section id="architecture-section-2" className="architecture-section">
    <div className="architecture-images">
      <img src={project7Image2} alt="Architecture Image 1" className="image1" />
      <img src={project7Image5} alt="Architecture Image 2" className="image2" />
    </div>
    <div className="architecture-text">
      <h2>Turnkey Fitouts</h2>
      <p>
        We, at Kottoor, specialize in turnkey fit-out, that is providing all
        services under one roof. This includes all kinds of architectural and
        MEP works, customized joinery and furniture, as well as handling permits
        and approvals to ensure successful project completion.
      </p>
      <ul>Turnkey Interior Fitout </ul>
      <ul>Fitout Contracting</ul>
      <p>
        Turnkey fit-out contracting is our core competency for the past two
        decades. We have completed numerous projects of various scales. We have
        state-of-the-art infrastructure and a highly skilled team to support the
        execution of work within the designated time and quality standards.
      </p>
    </div>
  </section>
);

const EngineeringSection2 = () => (
  <section id="engineering-section-2" className="engineering-section">
    <div className="engineering-text">
      <h2>Furniture</h2>
      <p>
        Various products under One Roof! We welcome you to experience our new
        designs and collection of furniture.
      </p>
    </div>
    <div className="engineering-images">
      <img src={project6Image5} alt="Engineering Image 1" className="image1" />
      <img src={project6Image6} alt="Engineering Image 2" className="image2" />
    </div>
  </section>
);

// Rest of the unchanged sections
const ServicesFeaturedProjects = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    beforeChange: (current, next) => setSelectedImage(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="services-featured-projects">
      <h2>Featured Projects</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div
            key={index}
            className={`services-slide ${
              selectedImage === index ? "selected" : ""
            }`}
            onClick={() => setSelectedImage(index)}
          >
            <img src={project.image} alt={project.alt} />
            <div className="services-overlay">
              <div className="services-circle">DRAG</div>
            </div>
            {selectedImage === index && (
              <Link to="/portfolio">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="services-search-icon"
                />
              </Link>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

const OurClients = () => (
  <section className="our-clients">
    <h2 className="clients-heading">Our Clients</h2>
    <div className="clients-row">
      <span className="client-name">REEM MALL</span>
      <span className="client-name">URBANISTI </span>
      <span className="client-name highlight">RAK PROPERTIES</span>
      <span className="client-name">VIA CONCEPT</span>
    </div>
    <div className="clients-row">
      <span className="client-name highlight">VKR LUBRICANTS</span>
      <span className="client-name">
        NATIONAL BANK OF FUJAIRAH DUBAI ISLAMIC BANK
      </span>
      <span className="client-name highlight">UKCBC COLLEGE</span>
    </div>
    <div className="clients-row">
      <span className="client-name highlight">RAK HOSPITAL</span>
      <span className="client-name">VFS GLOBAL </span>
      <span className="client-name highlight">DUBAI PROPERTLES</span>
      <span className="client-name">EMIRATES GOLD EMPRESS</span>
    </div>
  </section>
);

const WorkWithUs = () => (
  <section className="work-with-us">
    <div className="work-with-us-container">
      <div className="work-with-us-image">
        <img src={join} alt="Team working" />
      </div>
      <div className="work-with-us-content">
        <h2 className="work-with-us-heading">Contracting</h2>
        <ul className="work-with-us-text">
          <li>ALL UNDER ONE ROOF!</li>
          <li>
            “Design, approvals, fitout, build, construction, completion process
            and certificates”
          </li>
          <li>This segment provides a unique service of all process.</li>
          <li>
            Facilities available under one roof which will relieve the client
            from various point of contacts to a single point of contact.
          </li>
          <li>
            For enquiries related to this segment, please feel free to contact
            us on 0555891992.
          </li>
        </ul>
        <a
          href="https://wa.me/971555891992?text=Hello%20Kottoor%20Interiors!%20I'm%20interested%20in%20learning%20more%20about%20your%20services."
          className="work-with-us-button2"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      </div>
    </div>
  </section>
);

const ServicesPage = () => (
  <div>
    <ServicesHero />
    <ServicesDescription />
    <ArchitectureSection />
    <EngineeringSection />
    <ServicesFeaturedProjects />
    <ArchitectureSection2 />
    <EngineeringSection2 />
    <OurClients />
    <WorkWithUs />
  </div>
);

export default ServicesPage;
