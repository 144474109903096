import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faLinkedin,
  faInstagram,
  faTwitter,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../images/logo_removebg.png";
import "./Header.css";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isNavOpen]);

  // Close the menu and navigate to the selected page
  const handleNavClick = (path) => {
    setIsNavOpen(false);
    navigate(path);
  };

  return (
    <AppBar position="fixed" className="appbar">
      <Toolbar className="header">
        <div className="logo">
          <Link to="/" onClick={() => handleNavClick("/")}>
            <img src={logo} alt="Logo" className="logo-img" />
          </Link>
        </div>
        <div className="social-media-icons">
          <a
            href="https://www.youtube.com/@kottoorinteriors180"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a
            href="https://www.linkedin.com/company/kottoor/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="https://www.instagram.com/kottoorinteriors?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://wa.me/94715589192"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
        <IconButton
          edge="end"
          aria-label="menu"
          onClick={toggleNav}
          className="menu-icon"
        >
          {isNavOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Toolbar>
      <div className={`nav-container ${isNavOpen ? "open" : ""}`}>
        {isNavOpen && (
          <nav className="nav">
            <ul className="nav-list">
              <li className="nav-item">
                <Link to="/about" onClick={() => handleNavClick("/about")}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/services"
                  onClick={() => handleNavClick("/services")}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/portfolio"
                  onClick={() => handleNavClick("/portfolio")}
                >
                  Our Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" onClick={() => handleNavClick("/contact")}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </AppBar>
  );
};

export default Header;
