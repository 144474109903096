import React from "react";
import "./ProjectPage.css";
import { Link } from "react-router-dom"; 

import projectphoto1 from "../images/projects/Project5/1.jpg";
import projectphoto2 from "../images/projects/Project5/2.jpg";
import projectphoto3 from "../images/projects/Project5/3.jpg";
import projectphoto4 from "../images/projects/Project5/4.jpg";
import projectphoto5 from "../images/projects/Project5/5.jpg";

const ProjectHero = () => {
  return (
    <section className="project-hero">
      <div className="hero-content">
        <h1>MAG Office Interiors</h1>
        <span className="location">Dubai, UAE</span>
        <p>
          Our associations with MAG is for multiple projects we have done fit
          out for many of their projects. The MAG head office in EFT at DIFC was
          fitted out by us. We have completed multiple office fit-outs for MAG
          and various other clients in both ETT North and South.
        </p>
      </div>
      <div className="hero-image-container">
        <img
          src={projectphoto1}
          alt="MAG Office Interiors"
          className="hero-image"
        />
      </div>
    </section>
  );
};

const FacilityMasterplan = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The master plan for MAG office interiors involved the strategic layout
          of various work zones, including open-plan workstations, private
          offices, and state-of-the-art meeting rooms. The design promotes a
          seamless workflow while ensuring that each area is equipped with the
          latest technology to support the team’s dynamic needs.
        </p>
      </div>
    </section>
  );
};

const ProjectSection = () => {
  return (
    <section className="section-sc-1vjgz5n-0 eeeNzp">
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu">
        <div className="ratio-img-wrapper-sc-8qesjk-1 project-image-sc-di9xrj-0">
          <img
            alt="High-tech Workstations"
            className="ratio-img-sc-8qesjk-0 dwEpAz"
            src={projectphoto2}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-1">
          The high-tech workstations are designed to provide a comfortable and
          efficient environment for employees. With modern desks, ergonomic
          chairs, and advanced computing setups, this area maximizes
          productivity while maintaining a sleek and professional appearance.
        </p>
      </div>
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu reverse">
        <div className="ratio-img-wrapper-sc-8qesjk-2 project-image-sc-di9xrj-0">
          <img
            alt="Executive Conference Room"
            className="ratio-img-sc-8qesjk-0 dwEpAz2"
            src={projectphoto3}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-2">
          The executive conference room features cutting-edge technology and
          luxurious furnishings, making it the ideal space for high-level
          meetings. The design incorporates advanced audiovisual systems and
          soundproofing to ensure privacy and clarity during important
          discussions.
        </p>
      </div>
    </section>
  );
};

const FacilityMasterplan2 = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          Additional features of the MAG office include a stylish reception
          area, elegant breakout spaces, and a sleek pantry. Each element of the
          design is carefully curated to reflect the brand’s identity while
          providing a comfortable and efficient environment for employees and
          visitors alike.
        </p>
      </div>
    </section>
  );
};

const UniqueSection = () => {
  return (
    <section className="uniqueBeNxCw">
      <div className="uniqueEBZEyY">
        <div className="uniqueECjdAA">
          <h2 className="uniqueFLhVRG">Explore More About Us</h2>
        </div>
        <div className="uniqueWOhqI">
          <div className="uniqueDZUreZ">
            <Link to="/services" aria-label="Services">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto4}
                    alt="Our Services"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Discover Our Services</h3>
                <span className="uniqueJxeAbl">View Services</span>
              </div>
            </Link>
          </div>
          <div className="uniqueDZUreZ">
            <Link to="/about" aria-label="About Us">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto5}
                    alt="About Us"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Learn About Our Journey</h3>
                <span className="uniqueJxeAbl">Who We Are</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPage5 = () => {
  return (
    <>
      <ProjectHero />
      <FacilityMasterplan />
      <ProjectSection />
      <FacilityMasterplan2 />
      <UniqueSection />
    </>
  );
};

export default ProjectPage5;
