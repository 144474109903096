import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faLinkedin,
  faInstagram,
  faTwitter,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./ContactUsPage.css";

import cardImage from "../images/contactus/card.jpeg";

const contacts = [
  {
    icon: faYoutube,
    name: "Youtube",
    link: "https://www.youtube.com/@kottoorinteriors180",
  },
  {
    icon: faLinkedin,
    name: "Linkedin",
    link: "https://www.linkedin.com/company/kottoor/",
  },
  {
    icon: faInstagram,
    name: "Instagram",
    link: "https://www.instagram.com/kottoorinteriors?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
  },
  { icon: faTwitter, name: "Twitter", link: "https://twitter.com" },
 
  {
    icon: faWhatsapp,
    name: "Whatsapp",
    link: "https://wa.me/0555891992",
  },
  {
    icon: faPhone,
    name: "Call: 0555891992",
    link: "tel:+0555891992",
  },
];

function ContactPage() {
  const form = useRef();
  const [doorOpen, setDoorOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_isl8mrn",
        "template_0pgmpz7",
        form.current,
        "UUj9QPzTOO6Qj-h55"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    e.target.reset(); // Optionally reset the form after submission
  };

  const handleDoorToggle = () => {
    setDoorOpen(!doorOpen);
    if (doorOpen) {
      setZoomLevel(1); // Reset zoom level when closing the door
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3)); // Max zoom level is 3x
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1)); // Min zoom level is 1x (original size)
  };

  return (
    <div className="contact-page-container">
      <div className="Container fakLAl">
        <div className="btAWOx">
          <div className="cwGKJh iJkwdP">
            <div className="RatioImg__Wrapper-sc-8qesjk-1 iJkwdP">
              <img
                src={require("../images/contactus/1.jpg")}
                alt="Marquette Office"
                className="RatioImg__Img-sc-8qesjk-0 dwEpAz"
              />
            </div>
          </div>
          <div className="isoNtD bZSVww">
            <div className="RatioImg__Wrapper-sc-8qesjk-1 bHipPv">
              <img
                src={require("../images/contactus/2.jpg")}
                alt="Brighton Office"
                className="RatioImg__Img-sc-8qesjk-0 dwEpAz"
              />
            </div>
          </div>
        </div>

        <div className="kQgmzP">
          <div className="fynJbO">
            <div className="dRgZKO">
              <h2 className="H__H4-sc-be5gk5-2 StwfE">Kottoor</h2>
              <address className="ffqjhh">
                Location – Wearhouse# 4, Ras al khor Industrial area 2, <br />
                Dubai, UAE
              </address>
            </div>
            <div className="dRgZKO" style={{ marginTop: "25px" }}>
              <h2 className="H__H4-sc-be5gk5-2 StwfE">Get In Touch</h2>
              <address className="ffqjhh">
                Mobile: 0555891992, Mobile: 0509380025
                <br />
                Email: <a href="mailto:sales@kottoor.com">
                  sales@kottoor.com
                </a>{" "}
                <br />
                Website:{" "}
                <a
                  href="http://www.kottoor.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.kottoor.com
                </a>
              </address>
            </div>
          </div>
          <form ref={form} onSubmit={sendEmail} className="GsYIv ContactForm">
            <input type="hidden" name="form-name" value="contact_idi" />
            <div className="ezXYZL">
              <label htmlFor="bot-field">
                Don't fill this out if you're human:
                <input name="bot-field" id="bot-field" />
              </label>
            </div>
            <input
              type="text"
              name="contactName"
              id="contactName"
              placeholder="Name"
              className="ContactForm__StyledInput-sc-14p1k92-1 eBIHJq"
            />
            <div className="ContactForm__InputWrap-sc-14p1k92-4 ehAtBL">
              <input
                type="email"
                name="contactEmail"
                id="contactEmail"
                placeholder="Email"
                className="ContactForm__StyledInput-sc-14p1k92-1 eBIHJq"
              />
            </div>
            <textarea
              id="contactMessage"
              name="contactMessage"
              placeholder="Message"
              className="ContactForm__StyledTextArea-sc-14p1k92-3 fhDgCJ"
            />
            <button
              type="submit"
              id="submit"
              className="UnstyledButton-sc-1lhpa8-0 Vucbf Button__StyledButton-sc-1coru9f-0 ggJyiH ContactForm__StyledSubmit-sc-14p1k92-5 gBMAvg"
            >
              Submit
            </button>
          </form>
        </div>
      </div>


      {/* Contact Us Section */}
     
    </div>
  );
}

export default ContactPage;
