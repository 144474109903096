import React from "react";
import "./ProjectPage.css";
import { Link } from "react-router-dom";

import projectphoto1 from "../images/projects/Project6/1.jpg";
import projectphoto2 from "../images/projects/Project6/2.jpg";
import projectphoto3 from "../images/projects/Project6/3.jpg";
import projectphoto4 from "../images/projects/Project6/4.jpg";
import projectphoto5 from "../images/projects/Project6/5.jpg";

const ProjectHero = () => {
  return (
    <section className="project-hero">
      <div className="hero-content">
        <h1>FEWA Office Interiors</h1>
        <span className="location">Dubai, UAE</span>
        <p>
          FEWA is a government organization and by means of successful tender we
          were selected to execute turnkey fit-out with a high-end finish and
          specialized materials.
        </p>
      </div>
      <div className="hero-image-container">
        <img
          src={projectphoto1}
          alt="FEWA Office Interiors"
          className="hero-image"
        />
      </div>
    </section>
  );
};

const FacilityMasterplan = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The master plan for FEWA's office interiors involved a strategic
          layout that maximizes natural light and offers a range of functional
          spaces. From executive offices to comfortable waiting areas, every
          detail is meticulously planned to enhance the user experience and
          promote a positive work environment.
        </p>
      </div>
    </section>
  );
};

const ProjectSection = () => {
  return (
    <section className="section-sc-1vjgz5n-0 eeeNzp">
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu">
        <div className="ratio-img-wrapper-sc-8qesjk-1 project-image-sc-di9xrj-0">
          <img
            alt="Executive Office Space"
            className="ratio-img-sc-8qesjk-0 dwEpAz"
            src={projectphoto2}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-1">
          The executive office space is designed with a blend of sophistication
          and comfort. High-quality materials, ergonomic furniture, and modern
          lighting solutions create an environment that supports both focused
          work and important meetings.
        </p>
      </div>
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu reverse">
        <div className="ratio-img-wrapper-sc-8qesjk-2 project-image-sc-di9xrj-0">
          <img
            alt="Elegant Meeting Room"
            className="ratio-img-sc-8qesjk-0 dwEpAz2"
            src={projectphoto3}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-2">
          The meeting room combines elegance with advanced technology, providing
          a space that is conducive to productive discussions. The warm tones,
          sleek furnishings, and state-of-the-art audiovisual equipment ensure
          that every meeting runs smoothly and comfortably.
        </p>
      </div>
    </section>
  );
};

const FacilityMasterplan2 = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          Additional areas within the FEWA office include inviting waiting
          lounges and stylish breakout spaces, all designed to create a cohesive
          and luxurious atmosphere. The use of modern design elements throughout
          the office reinforces the organization’s forward-thinking approach.
        </p>
      </div>
    </section>
  );
};

const UniqueSection = () => {
  return (
    <section className="uniqueBeNxCw">
      <div className="uniqueEBZEyY">
        <div className="uniqueECjdAA">
          <h2 className="uniqueFLhVRG">Explore More About Us</h2>
        </div>
        <div className="uniqueWOhqI">
          <div className="uniqueDZUreZ">
            <Link to="/services" aria-label="Services">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto4}
                    alt="Our Services"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Discover Our Services</h3>
                <span className="uniqueJxeAbl">View Services</span>
              </div>
            </Link>
          </div>
          <div className="uniqueDZUreZ">
            <Link to="/about" aria-label="About Us">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto5}
                    alt="About Us"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Learn About Our Journey</h3>
                <span className="uniqueJxeAbl">Who We Are</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPage6 = () => {
  return (
    <>
      <ProjectHero />
      <FacilityMasterplan />
      <ProjectSection />
      <FacilityMasterplan2 />
      <UniqueSection />
    </>
  );
};

export default ProjectPage6;
