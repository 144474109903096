import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import AboutUsPage from "./components/AboutPage";
import ServicesPage from "./components/ServicesPage";
import Footer from "./components/Footer";
import ProjectPage1 from "./components/ProjectPage1";
import ProjectPage2 from "./components/ProjectPage2";
import ProjectPage3 from "./components/ProjectPage3";
import ProjectPage4 from "./components/ProjectPage4";
import ProjectPage5 from "./components/ProjectPage5";
import ProjectPage6 from "./components/ProjectPage6";
import ProjectPage7 from "./components/ProjectPage7";
import ProjectPage8 from "./components/ProjectPage8";
import ContactPage from "./components/ContactUsPage";
import SpotlightSection from "./components/SpotlightSection";
import "./App.css";

// Import the audio clip
import homepageAudio from "./audio/kottor.mp3"; // Replace with your path

function App() {
  const location = useLocation();
  const [isNavigating, setIsNavigating] = useState(false);

  // Monitor navigation changes
  useEffect(() => {
    if (location.pathname !== "/") {
      setIsNavigating(true);
    } else {
      setIsNavigating(false);
    }
  }, [location]);

  // This effect will hide the bars after a short time when navigating
  useEffect(() => {
    if (isNavigating) {
      const timer = setTimeout(() => setIsNavigating(false), 500); // Hide bars after 500ms
      return () => clearTimeout(timer);
    }
  }, [isNavigating]);

  // This effect will play audio when the home page loads
  useEffect(() => {
    if (location.pathname === "/") {
      const audio = new Audio(homepageAudio);
      audio.play().catch((error) => {
        console.error("Audio failed to play:", error);
      });
    }
  }, [location]);

  return (
    <div className="App">
      <Header />
      {/* Only show the bars when navigating */}
      {isNavigating && (
        <div className="diagonal-bars">
          <div className="diagonal-bar"></div>
          <div className="diagonal-bar"></div>
          <div className="diagonal-bar"></div>
          <div className="diagonal-bar"></div>
        </div>
      )}
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="page-transition"
          timeout={500}
        >
          <>
            <Routes location={location}>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutUsPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/portfolio" element={<SpotlightSection />} />
              <Route path="/ProjectPage1" element={<ProjectPage1 />} />
              <Route path="/ProjectPage2" element={<ProjectPage2 />} />
              <Route path="/ProjectPage3" element={<ProjectPage3 />} />
              <Route path="/ProjectPage4" element={<ProjectPage4 />} />
              <Route path="/ProjectPage5" element={<ProjectPage5 />} />
              <Route path="/ProjectPage6" element={<ProjectPage6 />} />
              <Route path="/ProjectPage7" element={<ProjectPage7 />} />
              <Route path="/ProjectPage8" element={<ProjectPage8 />} />
            </Routes>
          </>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </div>
  );
}

function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default WrappedApp;
